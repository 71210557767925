<template>
  <app-list-view
    server-side
    app="human_asset"
    model="humanassetgroup"
    api-url="human-asset/human-asset-group/"
    :title="$t('menu.humanAssetGroup')"
    :createTo="{ name: 'humanAssetGroupCreate' }"
    :editTo="{ name: 'humanAssetGroupEdit' }"
    :headers="headers"
  />
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'humanAssetCategoryList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        { text: this.$t('fields.humanAssetGroupName'), value: 'name' },
        { text: this.$t('fields.company'), value: 'company.name' },
        { text: this.$t('fields.state'), value: 'state' },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  },
  methods: {}
}
</script>
